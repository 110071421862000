import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';

import {withRouter} from 'react-router-dom';

import Auth from './lib/Auth';
import * as Env from './lib/Env';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function Login({ history }) {
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    message: '',
    showPassword: false,
    processing: false
  });

  const handleLogin = (username, password) => {
    setValues({ ...values, processing: true, message: '' });
    window.fetch(Env.LOGIN_URL, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    }).then(response => {
      return response.json();
    }).then(resp => {
      if (resp.code === 400) {
        return;
      }

      if (resp.code === 406) {
        return;
      }

       Auth.clear_local_storage();
       const dtoken = Auth.setup_sso(resp['id_token'], resp['refresh_token']);
       history.push('/')
    }, function(error) {
      setValues({ ...values, processing: false, message: 'Login Failed' });
    });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1, margin: '50px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Login for Support Access</h1>
        </Grid>
        <Grid item xs={12}>
          <p>{values.message}</p>
        </Grid>
        <Grid item xs={8}>
          <form
                onSubmit={event => {
                  handleLogin(values.username, values.password);
                  event.preventDefault();
                }}
          >
            <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
              <FilledInput
                id="filled-adornment-username"
                value={values.weight}
                onChange={handleChange('username')}
                aria-describedby="filled-weight-helper-text"
                inputProps={{
                  'aria-label': 'username',
                }}
              />
              <FormHelperText id="filled-username-helper-text">Username</FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined"
            >
              <FilledInput
                id="filled-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="filled-password-helper-text">Password</FormHelperText>
            </FormControl>
            { !values.processing && (<Button variant="contained" sx={{ marginTop: '20px' }} type="submit">Log In</Button>) }
            { values.processing && (<CircularProgress />) }
          </form>
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(Login);
