import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import Auth from './lib/Auth';
import * as Env from './lib/Env';

import WebStorage from './lib/WebStorage';

class Authenticator extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  handleRedirect = (success = true, smb = false) => {
    this.props.history.push('/');
  };

  componentDidMount() {
    const { handleRedirect } = this;
    const { errorMessage } = this.props;

    const sso = WebStorage.get(Env.SSO_SESSION_STORE);

    window
      .fetch(Env.SUPPORT_AUTH_URL, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({"sso": sso}),
      })
      .then(response => {
        if (response.status !== 200) {
          throw 'Unauthorized'
        }

        return response.json();
      })
      .then(response => {
        const account = WebStorage.get(Env.ACCOUNT);
        const name = WebStorage.get(Env.ACCOUNT_NAME);

        const dtoken = response.payload;
        // Set up token for Support
        Auth.setup_support(dtoken);

        this.props.history.push('/');
      })
      .catch(error => {
        this.props.history.push('/login');
      });
  }

  render() {
    return <div />;
  }
}

export default Authenticator;
