import React from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './Browse.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { withStyles } from '@mui/styles';

import WebStorage from './lib/WebStorage';
import * as Env from './lib/Env'

const XMLParser = require('react-xml-parser');

class Browse extends React.Component {

  state = {
    files: [],
  }


  componentDidMount() {
    const sso = WebStorage.get(Env.SSO_SESSION_STORE);
    let params = { 'sso': sso };

    axios.post(Env.SUPPORT_URL, params)
          .then(res => res.data)
          .then(response => {
            const contents = response;
            let files = [];
            for (var i = 0; i < contents.length; i++) {
              let name = contents[i]['Key'];
              let date = contents[i]['LastModified'];
              files.push({name, date});
            }

            const sortedFiles = files.sort((a,b) => (a['date'] < b['date']) ? 1 : -1);

            this.setState({
              files: sortedFiles,
            });
          });
  }

  getFile(file) {
    const sso = WebStorage.get(Env.SSO_SESSION_STORE);
    let params = { 'sso': sso };

    axios.post(Env.SUPPORT_URL + "/" + file, params)
          .then(res => res.data)
          .then(response => {
            console.log('the link: ', response);
            window.open(response['url'], '_blank');
          });
  }

  render() {
    const { classes } = this.props;

    return (
        <div className={classes.app}>
          <h1> Support Logs </h1>
          <p> These are the zip files generated by running the support tool on the windows machine. Must be within Barracuda Network to access files </p>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              { this.state.files.map(f => (
                <TableRow key={f.name}>
                  <TableCell>
                    {f.date}
                  </TableCell>
                  <TableCell>
                    {f.name}
                  </TableCell>
                  <TableCell>
                      <Link onClick={(e) => {console.log("this link: ", f.name); this.getFile(f.name); e.preventDefault(); } }href={`https://bcs-support.s3.amazonaws.com/${f.name}`} target="_blank">Download</Link>
                  </TableCell>
               </TableRow>
              ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
    );
  }
}

const styles = theme => ({
  app: {
    margin: '50px',
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

export default withStyles(styles)(Browse);
