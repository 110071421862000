import React from 'react';
import './App.css';
import { Switch, BrowserRouter, Redirect, Route } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { withStyles } from '@mui/styles';

import Browse from './Browse';
import Login from './Login';
import Authenticator from './Authenticator';

import Auth from './lib/Auth';

const XMLParser = require('react-xml-parser');


class App extends React.Component {

  state = {
    files: [],
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/authenticator" component={Authenticator} />
              <PrivateRoute component={Browse} />
            </Switch>
        </BrowserRouter>
      </div>
    );
  }
}


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/authenticator',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const styles = theme => ({
});

export default withStyles(styles)(App);
